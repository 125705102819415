export default [
  {
    title: 'จัดการคำร้องเรียน',
    route: 'home',
    icon: 'EditIcon',
  },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
]
